<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="4" sm="8" xs="12" class="d-flex justify-space-around">
        <v-card class="card-shadow border-radius-xl" width="363" min-width="363" max-width="363">
          <v-form ref="frm">
            <div class="card-padding text-center" align="center" justify="center" flex>
              <v-row justify-space-between>
                <v-col class="d-flex justify-space-around">
                  <v-img contain align="center" justify="center" class="navbar-brand-img ms-3" width="10" max-width="120"
                    style="border-radius: 50%;" src="../../../../assets/img/curved-images/EASTEA_LOGO_LOGIN.jpeg"></v-img>
                </v-col>
              </v-row>
            </div>

            <div class="card-padding">
              <p class="text-center" v-show="is_error" style="color: red; min-height: 20px">
                {{ error }}
              </p>
              <v-text-field hide-details="auto" outlined background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)"
                light height="40" placeholder="Username" :rules="[(v) => !!v || 'Please Enter valid username']"
                v-model="authData.email" @click="is_error = false" class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                ">
              </v-text-field>

              <v-text-field hide-details="auto" outlined background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)"
                light height="40" placeholder="Password" :rules="[(v) => !!v || 'Please Enter valid password']"
                @click="is_error = false" type="password" v-model="authData.password" class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                ">
              </v-text-field>

              <v-btn elevation="0" :ripple="false" height="43" dark class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                  green
                " @click="login()" color="#008946" small>Sign In</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "sign-up-basic",
  data() {
    return {
      is_error: false,
      authData: {
        email: "",
        password: "",
      },
      error: "",
    };
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    login() {
      if (this.validate()) {
        axios
          .post("auth-module/Login-vendor", this.authData)
          .then(async (res) => {
            console.log("res", res);
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("name", res.data.name);
            localStorage.setItem("code", res.data.code);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.token;

            this.$router.push({ name: "VendorOffer" });
          })
          .catch((err) => {
            console.log("err", err);
            this.error = err.response.data.message;
            this.is_error = true;
          });
      }
    },
  },
  created() {
    let token = localStorage.getItem("token");

    console.log(token);
    if (localStorage.getItem("token") != null) {
      this.$router.push({ name: "VendorOffer" });
    }
  },
};
</script>
